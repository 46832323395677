*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Barlow", sans-serif;
}

body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

#root > div {
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

table td .btn-sm {
  margin-left: 10px;
  padding: 0rem 0.5rem;
  font-size: 11px !important;
  margin-top: -15px;
}

.table {
  margin-bottom: inherit;
  color: #000000;
}

.table-sm td {
  padding: 0.9rem 0.5rem 0.5rem 0.5rem;
}

/* .table-sm th,
table td {
  display: table-cell;
  vertical-align: middle;
}

caption {
  padding-bottom: inherit;
} */

/* sticky-alert */
.sticky-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

/* .react-datepicker-popper {
  z-index: 5 !important;
  width: max-content;
  transform: translate(0, 31px) !important;
} */

/* Caso os breakpoints do gridsystem do bootstrap sejam modificados, essas regras
devem ser revistas.

A regra implementada aqui define que o último datepicker de cada linha esteja
posicionado partindo da direita. Isso evita que o datepicker aumente a largura do
formulário gerando scroll-x desnecessário */

@media (min-width: 1200px) {
  div.row div.col-xl-3:nth-child(4n) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-xl-3:nth-child(4n) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  div.row div.col-lg-4:nth-child(3n) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-lg-4:nth-child(3n) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

@media (max-width: 992px) {
  div.row div.col-6:nth-child(even) .react-datepicker-popper {
    right: 0;
    left: auto !important;
  }

  div.row div.col-6:nth-child(even) .react-datepicker__triangle {
    right: 50px !important;
    left: auto !important;
  }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-weight: 500 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
}

/* input group */
.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.input-group-sm > .react-datepicker-wrapper {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group-sm > .react-datepicker-wrapper > .react-datepicker__input-container {
  flex: 1 1 auto;
  margin-bottom: 0;
}

.input-group > .react-datepicker-wrapper > .react-datepicker__input-container > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: 0;
}

/* input group */
.nav-tabs .nav-link.active {
  font-weight: 500;
}

.nav-tabs .nav-link:focus {
  outline: none;
  box-shadow: none !important;
}

.input-find-relation-classes {
  margin-top: 1rem !important;
  border-radius: 0;
  border-top-left-radius: 0.25rem !important;
  border-bottom: none;
  border-right: none;

  padding: 0.938em 4em 0.938em 1em;
  font-size: 1em;
  width: 100%;
  min-height: 39px;
}

input[list] {
  width: 100%;
}

@media (max-width: 768px) {
  .input-find-relation-classes {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .mb-not {
    margin-bottom: 0 !important;
  }

  .mt-not {
    margin-top: 0 !important;
  }
}

/**** input with clear button ****/

form.search-form {
  position: relative;
}

/*** List Component ***/
.list-for-autocomplete div.active {
  background-color: #ccc;
}

.list-for-autocomplete label {
  display: inline-block;
}

.list-for-autocomplete input {
  margin-right: 0.4em;
}

/* table orderby */
.sort-icon {
  opacity: 0.5;
  cursor: pointer;
}

.sort-icon-active {
  cursor: pointer;
}

/* table cel wrap */
.text-wrapping {
  word-wrap: break-word;
}

.filter-field {
  flex-grow: 1;
  flex-shrink: 1;
  width: fit-content;
  height: fit-content;
}

@media (min-width: 768px) {
  .filter-field {
    flex-basis: calc(100% / 2);
  }
}

@media (min-width: 992px) {
  .filter-field {
    flex-basis: calc(100% / 3);
  }
}

@media (min-width: 1200px) {
  .filter-field {
    flex-basis: calc(100% / 4);
  }
}

@media (max-width: 576px) {
  .field-sm {
    flex-grow: 1;
  }
}

/* Operators */
.operator-container {
  position: absolute;
  top: 30px;
  margin-left: 3px;

  @media (min-width: 576px) {
    bottom: 0;
    top: auto;
  }
}

.operator-container button.current {
  background-color: transparent;
  border: none;
  width: 40px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: 2px solid rgb(171, 190, 209);
}

.operator-container button.current {
  box-shadow: none !important;
}

.operator-container .list {
  position: absolute;
  overflow: hidden;
  left: -3px;
  min-width: 43px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  border-radius: 4px;
  z-index: 1;
}

.operator-container .list button {
  box-shadow: none !important;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 36px;
}

.operator-container .list button:hover {
  background-color: rgb(0, 107, 255);
  color: #ffffff;
}

.operator-container .list button:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* FilterFields action buttons */
.filterFields-action-buttons button {
  height: 44px;
}

.filterFields-action-buttons button:first-child {
  margin-right: 4px;
}

.filterFields-action-buttons label {
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 0px 0px 0px 4px;
}

/* Relation Field */
.relation-field-container {
  position: relative;
}

.relation-field-container .suggestions {
  position: absolute;
  z-index: 1;
  width: 100%;
  max-height: 200px !important;
  overflow-y: auto;
}

.relation-field-container .suggestions ul {
  height: fit-content;
}

/**/
.navigation-slider-title {
  color: #ffffff;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 100%;
}

.row-navigation-slider-level_1 {
  background-color: rgb(0, 64, 153);
}

.row-navigation-slider-level_2 {
  background-color: rgb(0, 64, 153);
}

.row-navigation-slider-level_3 {
  background-color: rgb(0, 107, 255);
}

.table {
  border-collapse: separate;
  border-spacing: 8px 0;
}

.table tbody tr:nth-of-type(even) td {
  background-color: #f7f8fa;
}

/* .table tbody tr:hover td {
  background-color: #f7f8fa;
} */

.table th {
  padding: 0.75rem 0;
  text-wrap: wrap;
}

.table td,
.table th {
  border: none;
  color: #303032;
}

.table td {
  font-size: 14px;
  vertical-align: middle;
  line-height: 20px;
}

.table th {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.table > :not(caption) > * > * {
  padding: 0.625rem;
}

@media (max-width: 768px) {
  .filterfield-column {
    padding-bottom: 1.5rem !important;
  }
  .row-navigation-slider {
    background-color: transparent;
  }
}

.breadcrumb {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  line-height: 16px;
}

.w-full {
  width: 100%;
}

@media (min-width: 768px) {
  .breadcrumb > a,
  .breadcrumb > span {
    border-left: 1px solid #303032;
    padding-left: 1rem;
  }

  .breadcrumb > :last-child {
    font-weight: bold;
  }

  .breadcrumb {
    display: block;
    width: fit-content;
  }
}

.loading-page {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 1);
}

.loading-page h2,
.loading-page p {
  text-align: center;
}

/* Help for undoing white-space: nowrap */
@media (max-width: 767px) {
  span {
    white-space: normal !important;
  }

  h2 > span > span:first-child {
    display: inline;
  }
}
/* Help for undoing white-space: nowrap */

.loading-place-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-limpar {
  width: 152px !important;
  font-weight: 600 !important;
  background-color: transparent !important;
  color: #006bff !important;
  border: 3px solid #006bff !important;
}

@media (max-width: 768px) {
  .button-limpar {
    width: 100% !important;
  }
}
